import { THEMES } from 'app/constants';
import { IPartnerState } from 'store';

export enum EMiniAppStartParams {
  REF_CODE = 'refCode_',
  COURSE_ID = 'courseId_',
  NAV_TO_SHOP = 'navToShop',
  NAV_TO_REFS = 'navToReferrals',
}

export enum EPresetIds {
  SUI = 'SUI',
  STELLAR = 'Stellar',
  MAGICBALL = 'MagicBall',
  WALLET_LEARN = 'Wallet_Learn',
}

export const HARDCODED_PARTNER_PRESETS: { [key: string]: IPartnerState } = {
  [EPresetIds.STELLAR]: {
    partnerID: EPresetIds.STELLAR,
    theme: THEMES.DARK,
  },
  [EPresetIds.SUI]: {
    partnerID: EPresetIds.SUI,
    theme: THEMES.DARK,
  },
  [EPresetIds.MAGICBALL]: {
    partnerID: EPresetIds.MAGICBALL,
    theme: THEMES.DARK,
  },
  [EPresetIds.WALLET_LEARN]: {
    partnerID: EPresetIds.WALLET_LEARN,
    theme: THEMES.DARK,
  },
};
