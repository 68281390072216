import { useNavigate } from 'react-router-dom';
import { useGetMiniAppInitData } from './getInitData';
import { useEffect, useRef } from 'react';
import { useGetIsAuth } from 'hooks';
import { useGetReferralInfo, useMiniAppStoryMethods } from 'components/pages/referrals/screen/utils';
import { EMiniAppStartParams, EPresetIds, HARDCODED_PARTNER_PRESETS } from './types';
import { ROUTES } from 'app/constants';
import { useDispatch } from 'react-redux';
import { setPartnerData } from 'store';

export const useHandleMiniAppStartParam = () => {
  const { startParam, initData } = useGetMiniAppInitData();
  const { tgRefLink, shareText } = useGetReferralInfo();
  const { shareStory, isInMiniApp } = useMiniAppStoryMethods(tgRefLink, shareText);
  const isAuth = useGetIsAuth();
  const navigate = useNavigate();
  const isInitRef = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    isInitRef.current = true;
  }, []);

  useEffect(() => {
    if (!startParam || !isInMiniApp || !isInitRef.current) return;
    if (startParam.includes(EMiniAppStartParams.REF_CODE) && !isAuth) {
      const refCode = startParam.replace(EMiniAppStartParams.REF_CODE, '');
      navigate(`${ROUTES.REFERRAL_LANDING_NOPARAM}/${refCode}`);
      return;
    }
    if (startParam.includes(EMiniAppStartParams.COURSE_ID)) {
      const cut = startParam.replace(EMiniAppStartParams.COURSE_ID, '');
      const [courseId, presetId] = cut.split('-');
      if (courseId) {
        if ((Object.values(EPresetIds) as string[]).includes(presetId)) {
          const preset = HARDCODED_PARTNER_PRESETS[presetId as EPresetIds];
          dispatch(setPartnerData(preset));
        }
        navigate(`${ROUTES.COURSES}/${courseId}`);
      }
      return;
    }
    if (startParam.includes(EMiniAppStartParams.NAV_TO_SHOP)) {
      navigate(ROUTES.SHOP);
      return;
    }
    if (startParam.includes(EMiniAppStartParams.NAV_TO_REFS) && isAuth) {
      shareStory();
      return;
    }
  }, [startParam, isAuth, initData]);
};
