import { useSelector } from 'react-redux';
import { getPageIsLoaded, ISpaceCustomization, setPageIsLoaded } from 'store';
import { createPortal } from 'react-dom';
import { useCallback, useEffect, useRef } from 'react';
import { dispatch, useGetPartnerId } from 'hooks';
import PartnerLoader from '../PartnerLoader';
import { PageLoader } from '../PageLoader';
import { useGetSpaceCustomization } from 'hooks/useGetSpaceCustomization';
import { EPortals, LOCAL_STORAGE_KEYS } from 'app/constants';

export const GlobalLoader = ({ isLoading: isForcedLoading = true, onLoad }: { isLoading?: boolean; onLoad?: () => void }) => {
  const pageIsLoaded = useSelector(getPageIsLoaded);
  const partnerId = useGetPartnerId();

  const storageCustomization = localStorage.getItem(LOCAL_STORAGE_KEYS.LOADER_CUSTOMIZATION);
  const preparedStorageCustomization = storageCustomization ? (JSON.parse(storageCustomization) as ISpaceCustomization) : undefined;
  const { loader: customization } = useGetSpaceCustomization({ previewCustomization: preparedStorageCustomization });

  const isLoggedRef = useRef(false);
  useEffect(() => {
    let timeout: any;
    if (!pageIsLoaded) {
      timeout = setTimeout(() => {
        return dispatch(setPageIsLoaded(true));
      }, 10000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [pageIsLoaded]);

  const handleAnimateIsExit = useCallback(() => {
    if (onLoad && pageIsLoaded) {
      onLoad();
    }
  }, [onLoad, pageIsLoaded]);

  const isLoading = isForcedLoading || !pageIsLoaded;

  useEffect(() => {
    if (!isLoggedRef.current) {
      isLoggedRef.current = true;
    }
  }, []);

  if (!!partnerId) {
    return createPortal(
      <PartnerLoader
        isFixed
        isLoading={true}
        bgImage={customization?.images?.bgImage}
        bgColor={customization?.bgColor}
        accentColor={customization?.accentColor}
      />,
      document.getElementById(EPortals.LOADING) as HTMLDivElement
    );
  }

  return createPortal(
    <PageLoader isFixed isLoading={isLoading} setAnimateIsExit={handleAnimateIsExit} />,
    document.getElementById(EPortals.LOADING) as HTMLDivElement
  );
};
