import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { createConfig, http, WagmiProvider } from 'wagmi';
import { walletConnect } from 'wagmi/connectors';
import { createWeb3Modal } from '@web3modal/wagmi/react';

import { mainnet } from 'viem/chains';

import { Environment } from 'app/constants';
// import { useGetSettings } from 'hooks';

const queryClient = new QueryClient();

// const { displayedName, logos } = useGetSettings();

const projectId = Environment.WalletConnectProjectId ?? '';

// const metadata = {
//   name: displayedName,
//   description: 'Gamified Educational platform fot incentivised learning, bridging the worlds of education and gaming together.',
//   url: window.location.origin,
//   icons: [logos.small],
// };

// see https://wagmi.sh/react/guides/connect-wallet
const wagmiConfig = createConfig({
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
  },
  connectors: [
    walletConnect({
      projectId,
      // metadata,
      showQrModal: false,
    }),
  ],
});

createWeb3Modal({ wagmiConfig, projectId });

export const WagmiWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient} children={children} />
    </WagmiProvider>
  );
};
