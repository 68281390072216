import { ICourse, ICourseFull, ICourseSkeleton, IResultCourse } from 'interface/courses';
import { customFetchBase } from 'store/utils';
import {
  IAnswerBody,
  IAnswerResponse,
  IClaimRewardsRequest,
  ICompleteSlideRequest,
  ICompleteSlideResponseError,
  ICompleteSlideResponseSuccess,
  ICourseForCompletionFetchResult,
  ICoursesFetchRes,
} from './types';
import { ILeftOffLesson } from 'components/pages/study/courses/left-off-lesson';
import { createApi } from '@reduxjs/toolkit/query/react';
import { EGroupTag } from 'app/constants';

export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  // refetchOnFocus: false,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  tagTypes: ['Course', 'StartedCourses'],
  endpoints: (builder) => ({
    getCourseForEditorByPartId: builder.query<ICourse, string>({
      query: (partId) => ({
        url: `/course/for-editor/${partId}`,
      }),
    }),
    getFullCourse: builder.query<ICourseFull, string>({
      query: (courseId) => {
        return {
          url: `/course/full/${courseId}`,
        };
      },
      keepUnusedDataFor: 100,
    }),
    getCourseById: builder.query<ICourse, string>({
      query: (courseId) => ({
        url: `/course/${courseId}`,
      }),
    }),
    getCoursesForWidget: builder.query<
      { items: { name: string; _id: string }[]; total: number; totalPages: number; page: number },
      { limit: number; page: number; groups: EGroupTag | 'my' }
    >({
      query: (params) => ({
        url: `/course/constructor/widget/search`,
        params,
      }),
    }),
    getCourseByChildId: builder.query<ICourse, string>({
      query: (id) => ({
        url: `/course/by-child-id/${id}`,
      }),
    }),
    getCourseSkeletonById: builder.query<ICourseSkeleton, string>({
      query: (id) => ({
        url: `/course/constructor/skeleton/${id}`,
      }),
    }),
    getCourseSkeletonByChildId: builder.query<ICourseSkeleton, string>({
      query: (id) => ({
        url: `/course/constructor/skeleton/by-child-id/${id}`,
      }),
    }),
    getCoursesSkeletonsBySpaceId: builder.query<ICourseSkeleton[], string>({
      query: (id) => ({
        url: `/course/constructor/skeleton/by-space-id/${id}`,
      }),
    }),
    getCourseWithProgress: builder.query<ICourse, string>({
      query: (courseId) => ({
        url: `/course/with-progress/${courseId}`,
      }),
      transformResponse: ({ course }: { course: ICourse }) => {
        return course;
      },
    }),
    getAllCourses: builder.query<ICoursesFetchRes, Record<string, boolean | string | number | any[]> | undefined>({
      query: (query) => {
        return {
          url: '/course',
          params: query,
        };
      },
    }),
    getCourseForCompletion: builder.query<ICourseForCompletionFetchResult, { courseId: string; chapterId?: string; lessonId?: string }>({
      query: ({ courseId, chapterId, lessonId }) => ({ url: `/course/for-completion/${courseId}/${chapterId}/${lessonId}` }),
    }),
    completeSlide: builder.mutation<ICompleteSlideResponseSuccess, ICompleteSlideRequest>({
      query: (data) => {
        return {
          url: '/result-slide/finish',
          method: 'PUT',
          body: data,
        };
      },
      transformErrorResponse: (res) => {
        return res as unknown as ICompleteSlideResponseError;
      },
      invalidatesTags: ['Course'],
    }),
    completeSlideUnauth: builder.mutation<ICompleteSlideResponseSuccess, Pick<ICompleteSlideRequest, 'slideId' | 'userAnswers'>>({
      query: (data) => ({
        url: '/result-slide/finish/unauth',
        body: data,
        method: 'PUT',
      }),
    }),
    checkAnswer: builder.mutation<IAnswerResponse, IAnswerBody>({
      query: (data) => {
        return {
          url: '/answer/check-answer',
          method: 'PUT',
          body: data,
        };
      },
    }),
    claimRewards: builder.mutation<{ resultCourse: IResultCourse }, IClaimRewardsRequest>({
      query: (body) => ({
        url: '/result-slide/claim-rewards',
        method: 'PUT',
        body,
      }),
    }),
    getLeftOffLesson: builder.query<ILeftOffLesson, void>({
      query: () => {
        return {
          url: '/result-lesson/last-lesson',
        };
      },
      keepUnusedDataFor: 100,
    }),
    getLeftOffLessonByCourseId: builder.query<ILeftOffLesson, string>({
      query: (courseId) => {
        return {
          url: `/result-lesson/last-lesson/${courseId}`,
        };
      },
    }),
    getStartedCourses: builder.query<any, any>({
      query: (body) => {
        return {
          url: '/result-course/started',
          method: 'PUT',
          body,
        };
      },
      providesTags: ['StartedCourses'],
    }),
    getResultCourseByCourseId: builder.query<IResultCourse, { courseId: string }>({
      query: ({ courseId }) => ({
        url: `/result-course/by-course-id/full/${courseId}`,
      }),
    }),
    // dev stuff
    flushCourse: builder.mutation<void, { resultCourseId: string }>({
      query: (body) => {
        return {
          url: '/result-course/flush/me',
          method: 'PUT',
          body,
        };
      },
    }),
    flushMyCourses: builder.mutation<any, void>({
      query: () => {
        return {
          url: '/result-course/flush/me/all',
        };
      },
      invalidatesTags: ['Course'],
    }),
    getStudentsCountByCourse: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-course/students-count/by-course-id/${id}`,
      }),
    }),
    getStudentsCountByChapter: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-chapter/students-count/by-chapter-id/${id}`,
      }),
    }),
    getStudentsCountByLesson: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-lesson/students-count/by-lesson-id/${id}`,
      }),
    }),
    getStudentsCountBySlide: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-slide/students-count/by-slide-id/${id}`,
      }),
    }),
  }),
});
export const {
  useGetCourseByIdQuery,
  useGetCourseByChildIdQuery,
  useGetCourseForEditorByPartIdQuery,
  useLazyGetAllCoursesQuery,
  useLazyGetFullCourseQuery,
  useCompleteSlideMutation,
  useCheckAnswerMutation,
  useLazyGetLeftOffLessonQuery,
  useLazyGetLeftOffLessonByCourseIdQuery,
  useGetLeftOffLessonByCourseIdQuery,
  useFlushCourseMutation,
  useFlushMyCoursesMutation,
  useLazyGetResultCourseByCourseIdQuery,
  useLazyGetCourseForCompletionQuery,
  useGetCourseForCompletionQuery,
  useLazyGetStartedCoursesQuery,
  useGetStartedCoursesQuery,
  useLazyGetStudentsCountByCourseQuery,
  useLazyGetStudentsCountByChapterQuery,
  useLazyGetStudentsCountByLessonQuery,
  useLazyGetStudentsCountBySlideQuery,
  useClaimRewardsMutation,
  useGetCourseWithProgressQuery,
  useLazyGetCourseWithProgressQuery,
  useGetLeftOffLessonQuery,
  useCompleteSlideUnauthMutation,
  useLazyGetCoursesForWidgetQuery,
  useGetCourseSkeletonByChildIdQuery,
  useGetCourseSkeletonByIdQuery,
  useGetCoursesSkeletonsBySpaceIdQuery,
  useGetStudentsCountByLessonQuery,
} = coursesApi;
