import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from '../utils';
import { IComment, IRewardMinimal } from 'store';
import { IAnswer } from './types';
import { ICommentCountResponse, ICommentResponse, IPostComment } from 'interface';

export const answersAndCommentsApi = createApi({
  reducerPath: 'answersAndCommentsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Answers', 'Comments'],
  endpoints: (builder) => ({
    getAnswersBySlideId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-slide-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    getAnswersByChapterId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-chapter-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    getAnswersByLessonId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-lesson-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    getAnswersByCourseId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-course-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    archiveAnswer: builder.mutation<any, { id: string; rewards?: IRewardMinimal[]; slideId: string }>({
      query: (payload) => ({
        url: '/hand-checked-answer/archive',
        method: 'PUT',
        body: payload,
      }),
    }),
    getComments: builder.query<ICommentResponse, { slideId: string; queryString: string }>({
      query: ({ slideId, queryString }) => `/comment/${slideId}${queryString}`,
      providesTags: (result, error, arg) => {
        return [{ type: 'Comments', id: arg.slideId }];
      },
    }),
    getCountComments: builder.query<ICommentCountResponse, { slideId: string }>({
      query: ({ slideId }) => `/comment/count/${slideId}`,
    }),
    postComment: builder.mutation<IComment, IPostComment>({
      query: (data: IPostComment) => ({
        url: `/comment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Comments', id: arg.slideId }];
      },
    }),
    rewardUserAndArchiveComment: builder.mutation<void, { commentId: string; rewards?: IRewardMinimal[]; slideId: string }>({
      query: (data) => ({
        url: `/comment/archive`,
        method: 'PUT',
        body: data,
      }),
    }),
    getCommentsBySlide: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-slide-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
    getCommentsByChapter: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-chapter-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
    getCommentsByLesson: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-lesson-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
    getCommentsByCourse: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-course-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
  }),
});

export const {
  useGetAnswersBySlideIdQuery,
  useLazyGetAnswersByChapterIdQuery,
  useLazyGetAnswersByCourseIdQuery,
  useLazyGetAnswersByLessonIdQuery,
  useArchiveAnswerMutation,
  useLazyGetAnswersBySlideIdQuery,
  usePostCommentMutation,
  useGetCommentsQuery,
  useRewardUserAndArchiveCommentMutation,
  useGetCountCommentsQuery,
  useLazyGetCommentsByChapterQuery,
  useLazyGetCommentsByCourseQuery,
  useLazyGetCommentsByLessonQuery,
  useGetCommentsByLessonQuery,
  useGetAnswersByLessonIdQuery,
  useLazyGetCommentsBySlideQuery,
  useGetCommentsBySlideQuery,
} = answersAndCommentsApi;
