import { useTonConnectUI } from '@tonconnect/ui-react';
import { PersistKeys, useLogOutMutation } from 'store';
import { useEVMAuth } from './useEVMAuth';
import { useCallback } from 'react';
import { persistRootKeyPrefix } from 'app/constants';
import { buildVersion } from 'version';

export const useLogout = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [logOut] = useLogOutMutation();
  const { handleDisconnect } = useEVMAuth();

  const logOutHandler = useCallback(async () => {
    await logOut()
      .unwrap()
      .then(() => {
        if (tonConnectUI.connected) {
          tonConnectUI.disconnect();
        }
        handleDisconnect();

        const currentPersistKey = `persist:${persistRootKeyPrefix}-${buildVersion}`;

        if (localStorage.getItem(currentPersistKey)) {
          const persistObj = JSON.parse(localStorage.getItem(currentPersistKey) || '{}');
          const newPersistObj = { ...persistObj, [PersistKeys.Profile]: { user: {}, isLoaded: false } };
          localStorage.setItem(currentPersistKey, JSON.stringify(newPersistObj));
        }
      })
      .catch(console.error);
  }, [logOut, tonConnectUI, handleDisconnect]);

  return logOutHandler;
};
