import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THEMES } from 'app/constants';
import { RootStateType } from 'store/store';

export enum EPartnerParams {
  // at study area, hides scrollbars, and the scrolling becomes disabled
  ScrollOff = 'scrollOff',
  // hides <Back button at auth page
  HideBackButton = 'hideBackButton',
  // at study area, hides scrollbars, but the scrolling itself is not disabled
  StudyScrollbarHidden = 'studyScrollbarHidden',
  // at study area, hides "Next Lesson" button in case it's about to be appear
  HideNextLessonBtn = 'hideNextLessonBtn',
  // at study area, hides header
  StudyHeadOff = 'studyHeadOff',
  // at study area, hides course navigation bar(right) and menu bar(left)
  HideSidebards = 'hideSidebards',
  // at study area, defines how many pixels is between lowest component in study area and the bottom of the screen
  StudyBottomMargin = 'studyBottomMargin',
  Theme = 'theme',
  PartnerID = 'partnerID',
  // used as example at space customization
  // in docs we use partnerId in camelCase, but it is converted to partnerID in widget init
  PartnerIDFromWidgetForReact = 'partnerId',
  PartnerIDFromWidgetForVanilla = 'partnerid',
}

export const DEFAULT_STUDY_PADDING = 65;

export interface IPartnerState {
  [EPartnerParams.PartnerID]?: string | null;
  [EPartnerParams.Theme]?: THEMES;
  [EPartnerParams.ScrollOff]?: boolean;
  [EPartnerParams.HideBackButton]?: boolean;
  [EPartnerParams.StudyScrollbarHidden]?: boolean;
  [EPartnerParams.HideSidebards]?: boolean;
  [EPartnerParams.StudyHeadOff]?: boolean;
  [EPartnerParams.HideNextLessonBtn]?: boolean;
  [EPartnerParams.StudyBottomMargin]?: number;
  // partnerToken: string | null;
}

const initialState: IPartnerState = {
  [EPartnerParams.PartnerID]: null,
  [EPartnerParams.Theme]: THEMES.DARK,
  [EPartnerParams.ScrollOff]: false,
  [EPartnerParams.HideBackButton]: false,
  [EPartnerParams.StudyScrollbarHidden]: false,
  [EPartnerParams.HideSidebards]: false,
  [EPartnerParams.StudyHeadOff]: false,
  [EPartnerParams.HideNextLessonBtn]: false,
  [EPartnerParams.StudyBottomMargin]: DEFAULT_STUDY_PADDING,
  // partnerToken: null,
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setPartnerData: (state, { payload }: PayloadAction<IPartnerState>) => {
      return { ...state, ...payload };
    },
    resetPartnerData: () => initialState,
  },
});

const partnerData = (state: RootStateType) => state.partner;
const partnerId = (state: RootStateType) => state.partner[EPartnerParams.PartnerID];
const scrollOff = (state: RootStateType) => state.partner[EPartnerParams.ScrollOff];
const hideBackButton = (state: RootStateType) => state.partner[EPartnerParams.HideBackButton];
const studyScrollbarHidden = (state: RootStateType) => state.partner[EPartnerParams.StudyScrollbarHidden];
const hideSidebards = (state: RootStateType) => state.partner[EPartnerParams.HideSidebards];
const studyHeadOff = (state: RootStateType) => state.partner[EPartnerParams.StudyHeadOff];
const hideNextLessonBtn = (state: RootStateType) => state.partner[EPartnerParams.HideNextLessonBtn];
const studyBottomMargin = (state: RootStateType) => state.partner[EPartnerParams.StudyBottomMargin];

export const { setPartnerData, resetPartnerData } = partnerSlice.actions;
export const getPartnerId = createSelector(partnerId, (partner) => partner);
export const getPartnerData = createSelector(partnerData, (partner) => partner);
export const getScrollOff = createSelector(scrollOff, (scrollOff) => scrollOff);
export const getHideBackButton = createSelector(hideBackButton, (hideBackButton) => hideBackButton);
export const getStudyScrollbarHidden = createSelector(studyScrollbarHidden, (studyScrollbarHidden) => studyScrollbarHidden);
export const getHideSidebards = createSelector(hideSidebards, (hideSidebards) => hideSidebards);
export const getStudyHeadOff = createSelector(studyHeadOff, (studyHeadOff) => studyHeadOff);
export const getHideNextLessonBtn = createSelector(hideNextLessonBtn, (hideNextLessonBtn) => hideNextLessonBtn);
export const getStudyBottomMargin = createSelector(studyBottomMargin, (studyBottomMargin) => studyBottomMargin);

export default partnerSlice.reducer;
