// RelativeTime.tsx
import React, { useState, useEffect } from 'react';

interface RelativeTimeProps {
  /**
   * Timestamp in milliseconds, can be a string or number
   * Example: "1741996754191" or 1741996754191
   */
  timestamp: string | number;
  /**
   * Whether to automatically update the time
   */
  autoUpdate?: boolean;
  /**
   * Interval in milliseconds to update the time
   */
  updateInterval?: number;
  /**
   * Optional CSS class name
   */
  className?: string;
}

/**
 * Format a timestamp as a relative time string (e.g., "just now", "3 min ago")
 * Accepts timestamps in milliseconds as either string or number
 */
const RelativeTime: React.FC<RelativeTimeProps> = ({ timestamp, autoUpdate = false, updateInterval = 60000, className }) => {
  const [formattedTime, setFormattedTime] = useState<string>('');

  const formatRelativeTime = (rawTimestamp: string | number): string => {
    try {
      // Convert to number if it's a string
      const timestampMs = typeof rawTimestamp === 'string' ? parseInt(rawTimestamp, 10) : rawTimestamp;

      // Verify the timestamp is valid
      if (isNaN(timestampMs)) {
        return 'Invalid timestamp';
      }

      // Convert to seconds for comparison (both now and timestamp)
      const now = Math.floor(Date.now() / 1000);
      const timestampSec = Math.floor(timestampMs / 1000);

      const seconds = now - timestampSec;

      // Handle future dates
      if (seconds < 0) {
        return 'in the future';
      }

      // Time intervals in seconds
      if (seconds < 5) {
        return 'just now';
      } else if (seconds < 60) {
        return `${seconds} seconds ago`;
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} ${minutes === 1 ? 'min' : 'mins'} ago`;
      } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} ${hours === 1 ? 'hr' : 'hrs'} ago`;
      } else if (seconds < 172800) {
        return 'yesterday';
      } else if (seconds < 604800) {
        const days = Math.floor(seconds / 86400);
        return `${days} days ago`;
      } else if (seconds < 2592000) {
        const weeks = Math.floor(seconds / 604800);
        return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
      } else if (seconds < 31536000) {
        const months = Math.floor(seconds / 2592000);
        return `${months} ${months === 1 ? 'month' : 'months'} ago`;
      } else {
        const years = Math.floor(seconds / 31536000);
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
      }
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'Invalid timestamp';
    }
  };

  const updateFormattedTime = (): void => {
    setFormattedTime(formatRelativeTime(timestamp));
  };

  useEffect(() => {
    updateFormattedTime();

    // Set up automatic updating if enabled
    let intervalId: number | undefined;
    if (autoUpdate) {
      intervalId = window.setInterval(updateFormattedTime, updateInterval);
    }

    return () => {
      if (intervalId !== undefined) {
        window.clearInterval(intervalId);
      }
    };
  }, [timestamp, autoUpdate, updateInterval]);

  return <span className={className}>{formattedTime}</span>;
};

export default RelativeTime;
